<template>
    <!-- <el-tabs type="border-card" class="m-1" style="height:90vh; overflow: scroll;" v-if="menu.includes('crm')"> -->
    <el-tabs type="border-card" class="m-1" style="height:90vh">
        <el-tab-pane :label="$t('pages.crm.complaints.title')" v-if="permissions.includes('complaints')">
            <ComplaintsPage />
        </el-tab-pane>
        <el-tab-pane :label="$t('pages.crm.customers.title')"  v-if="permissions.includes('customers')">
            <CustomersPage />
        </el-tab-pane>
        <el-tab-pane :label="$t('pages.crm.suppliers.title')" v-if="permissions.includes('suppliers')">
            <SuppliersPage />
        </el-tab-pane>
        <el-tab-pane :label="$t('pages.crm.visitors.title')" v-if="permissions.includes('visitors')">
            <VisitorsPage />
        </el-tab-pane>
    </el-tabs>
    <!-- <el-empty description="Vous n'avez pas accès !" v-else></el-empty> -->
</template>

<script>
    
    import CustomersPage from './CRM/Customers/CustomersPage.vue';
    import ComplaintsPage from './CRM/Complaints/ComplaintsPage.vue';
    import SuppliersPage from './CRM/Suppliers/SuppliersPage.vue';
    import VisitorsPage from './CRM/Visitors/VisitorsPage.vue';

    export default {

        components: {
            CustomersPage,
            ComplaintsPage,
            SuppliersPage,
            VisitorsPage,
        },

        data() {
            return {
                permissions: [],
            }
        },

        created(){
            this.permission();
        },

        methods:{
            
            permission() {
                this.permissions =  JSON.parse(localStorage.getItem('menu'));
                    console.log('perm', this.permissions);
                this.permissions = this.permissions.crm[0];
            },

        },
    }
</script>