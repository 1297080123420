<template>
    <div>
        <el-tabs v-model="activeName" style="height:80vh;">
            <el-tab-pane :label="$t('pages.crm.customers.sections.customers.title')" name="first">
                <CustomersList />
            </el-tab-pane>
            <el-tab-pane :label="$t('pages.crm.customers.sections.groups.title')" name="second">
                <CustomersGroups />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>

    import CustomersList from './Content/CustomersList.vue';
    import CustomersGroups from './Content/CustomersGroups.vue';

    export default {

        components: {
            CustomersList,
            CustomersGroups,
        },
        
        data() {
            return {
                activeName: 'first',
            }
        }
    }
</script>