<template>
    <div class="m-3">
        <v-row>
            <v-col cols="12" md="4">
                <v-text-field class="purple-input" v-model="form.user.name" :label="$t('pages.crm.complaints.modal.user')" disabled/>
            </v-col>
            <v-col cols="12" md="4" >
                <el-date-picker v-model="form.open_date" type="date" :placeholder="$t('pages.crm.complaints.modal.open_date')" style="width:80%; border-radius:0px" class="mt-3" disabled></el-date-picker>
            </v-col>
            <v-col cols="12" md="4" >
                <v-text-field class="purple-input" v-model="form.title" :label="$t('pages.crm.complaints.modal.title')"/>
            </v-col>
            <v-col cols="12" md="4" >
                <v-text-field class="purple-input" v-model="form.phone_1" :label="$t('pages.crm.complaints.modal.phone1')" />
            </v-col>
            <v-col cols="12" md="4" >
                <v-text-field class="purple-input" v-model="form.phone_2" :label="$t('pages.crm.complaints.modal.phone2')" />
            </v-col>
            <v-col cols="12" md="4" >
                <el-select v-model="form.state" clearable :placeholder="$t('pages.crm.complaints.modal.state')" style="width:80%; border-radius:0px" class="mt-3">
                    <el-option v-for="item in options_state" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </v-col>
            <v-col cols="12" md="12">
                <vue-editor id="editor" v-model="form.description" >
                </vue-editor>
            </v-col>
            <v-col cols="12" md="3" class="mt-3">
                <el-switch v-if="is_close == 0" style="display: block" v-model="close" class="mt-3" active-color="#13ce66" inactive-color="#DE1C1C" 
                :inactive-text="$t('pages.crm.complaints.modal.is_close')" :active-value="act_val" :inactive-value="inact_val" ></el-switch>

                <el-switch v-else style="display: block" v-model="form.is_close" class="mt-3" active-color="#13ce66" inactive-color="#DE1C1C" 
                :inactive-text="$t('pages.crm.complaints.modal.is_close')" :active-value="act_val" :inactive-value="inact_val" disabled></el-switch>

            </v-col>
            <v-col cols="12" md="4">
                <el-date-picker v-if="is_close == 0" v-model="form.close_date" type="date" :placeholder="$t('pages.crm.complaints.modal.close_date')" style="width:80%; border-radius:0px" class="mt-3"></el-date-picker>
                <el-date-picker v-else v-model="form.close_date" type="date" :placeholder="$t('pages.crm.complaints.modal.close_date')" style="width:80%; border-radius:0px" class="mt-3" disabled></el-date-picker>
            </v-col>
        </v-row>
        <v-card-actions v-if="is_close == 0">
                <v-spacer />
                <v-row>
                    <v-col cols="12" class="text-right" >
                        <el-button type="danger" @click="closeModal()" class="br-0">Annuler</el-button>
                        <el-button type="primary" @click="handleSave()" class="br-0">Enregistrer</el-button>
                    </v-col>
                </v-row>
        </v-card-actions>
        
    </div>
</template>
<script>

    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';
    import axios from 'axios';
    import moment from 'moment';

    // Import vue editor
    import { VueEditor } from "vue2-editor";


    export default {

        props: ['loadData','closeModal', 'type', 'form', 'is_close'],

        components: {
            VueEditor
        },

        data(){
            return {
                form_empty: '',
                value: [],
                act_val: 1,
                inact_val: 0,
                options: [],
                loading: false,
                curent_user: {},
                options_state: [{
                        value: 0,
                        label: this.$t('pages.crm.complaints.modal.state_label.open')
                    }, {
                        value: 1,
                        label: this.$t('pages.crm.complaints.modal.state_label.progress')
                    }, {
                        value: 2,
                        label: this.$t('pages.crm.complaints.modal.state_label.finish')
                    }
                ],

                close: 0,
            }
        },

        methods: {

            async handleSave(){

                NProgress.start();

                

                if (this.form.close_date) {
                    this.form.close_date = moment(new Date(this.form.close_date)).startOf('day').format('YYYY-MM-DD');
                }

                try {

                    await axios.put("v1/xperf/crm/update/complaints", this.form);
                    
                    this.value = [];
                    this.loadData();
                    this.closeModal();
                    this.form = this.form_empty;

                    this.$swal(
                        ""+this.$t('global_alert_succes_title')+"",
                        ""+this.$t('global_alert_succes_text')+"",
                        'success'
                    );

                } 
                catch (error) {

                    this.loading = false;
                    this.$swal(
                        ""+this.$t('global_alert_echec_title')+"",
                        ""+this.$t('global_alert_echec_text')+"",
                        'error'
                    );
                }
                
                NProgress.done();
            }
        },

        watch: {
            value: function () {

                this.options.forEach(element => {
                    if (element.value == this.value) {
                        this.form = element;
                    }
                });

            },
            close: function(){
                
                if (this.close == 1) {
                    this.form.is_close = 1
                    this.form.close_date = moment().toDate();
                    this.form.state = 2;
                }
                else {
                    this.form.is_close = 1
                    this.form.close_date = '';
                    this.form.state = 1;
                }
            },
        },
        
    }
</script>
<style lang="scss" scoped>
    .br-0{
        border-radius: 0px;
    }
</style>