<template>
    <div class="p-1">
        
        <v-row class="d-flex justify-content-between">
            <v-col cols="4">
                <el-select v-model="curent_state" @change="filterData()" :placeholder="$t('pages.crm.complaints.modal.state_label.title')" style="width:100%">
                    <el-option v-for="item in options_state" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
            </v-col>
            <v-col cols="6" class="d-flex justify-content-end">
                <el-button type="primary" icon="el-icon-refresh" @click="handleRefresh()" style="border-radius:0px;" size="mini">{{$t('pages.crm.customers.sections.customers.refresh')}}</el-button>
                <el-button type="primary" icon="el-icon-plus" @click="handleAddComplaint()" style="border-radius:0px;" size="mini">{{$t('pages.crm.customers.sections.customers.add-customers')}}</el-button>
            </v-col>
        </v-row>

        <el-divider ></el-divider>

        <el-empty v-if="all_data.length < 1" :description="$t('global_no_data')"></el-empty>
        <div v-else>
            <v-row class="d-flex">
                <v-col :cols="tableCols">
                    <el-skeleton :rows="20" animated v-if="loading" />
                    <ve-table :row-style-option="rowStyleOption" :columns="columns" :table-data="tableData" :border-around="true" max-height="65vh" :border-x="true" :border-y="false"></ve-table>
                </v-col>
            </v-row>
        </div>
        <ui-modal ref="modal1" :title="title" size="large" >
            <Addcomplaints v-if="type==1" v-bind:loadData="loadData" v-bind:closeModal="closeModal"/>
            <Editcomplaints v-if="type==2" v-bind:loadData="loadData" v-bind:closeModal="closeModal" v-bind:form="form" v-bind:is_close="is_close"/>
        </ui-modal>

    </div>

</template>
<script>

    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';
    import axios from 'axios';

    import Addcomplaints from '@/components/dashboard/crm/complaints/Addcomplaints.vue';
    import Editcomplaints from '@/components/dashboard/crm/complaints/Editcomplaints.vue';

    export default {

        components: {
            Addcomplaints,
            Editcomplaints,
        },
        
        data() {
            return {

                loading: true,

                all_data: [],
                tableData: [],
                
                showAdd: false,
                tableCols: 12,

                titleAdd : this.$t('pages.crm.complaints.modal.title_add'),
                titleEdit : this.$t('pages.crm.complaints.modal.title_edit'),
                title: '',
                search: '',
                type: 1,
                is_close: null,

                curent_state: null,
                options_state: [{
                        value: 0,
                        label: this.$t('pages.crm.complaints.modal.state_label.open')
                    }, {
                        value: 1,
                        label: this.$t('pages.crm.complaints.modal.state_label.progress')
                    }, {
                        value: 2,
                        label: this.$t('pages.crm.complaints.modal.state_label.finish')
                    }
                ],

                form: {
                    id  : "",
                    name  : "",
                    surname : "",
                    username : "",
                    service : "",
                    function : "",
                    profile : "",
                    password : "",
                    email : "",
                    sexe : "",
                    code_postal : "",
                    agence_id : null,
                    rue : "",
                    ville : "",
                    quartier : "",
                    phone : null,
                    phone_p : null,
                    date_naiss : null
                },

                rowStyleOption: {
                    stripe: true,
                },
                columns: [
                    {
                        field: "",
                        key: "a",
                        title: "n°",
                        align: "center",
                        width: '5%',
                        renderBodyCell: ({ row, column, rowIndex }, h) => {
                            return ++rowIndex;
                        },
                    },
                    {
                        field: "user",
                        key: "c",
                        title: this.$t('pages.crm.complaints.modal.user'),
                        align: "left",
                        width: '10%',
                         renderBodyCell: ({ row, column, rowIndex }, h) => {
                            return (
                                <span class="subtitle-1 font-weight-light">
                                    {row.user.name}
                                </span>
                            );
                        },
                    },
                    {
                        field: "title",
                        key: "b",
                        title: this.$t('pages.crm.customers.sections.groups.table_header.title'),
                        align: "left",
                        width: '20%',
                        renderBodyCell: ({ row, column, rowIndex }, h) => {
                            return (
                                <div class="subtitle-1 font-weight-light">
                                    {row.title}
                                </div>
                            );
                        },
                    },
                    {
                        field: "description",
                        key: "e",
                        title: this.$t('pages.crm.customers.sections.groups.table_header.desc'),
                        align: "left",
                        width: '65%',
                        renderBodyCell: ({ row, column, rowIndex }, h) => {
                            return (
                                <span class="subtitle-1 font-weight-bold" style="display: block; width: 100vh; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                                    {row.description}
                                </span>
                            );
                        },
                    },
                    {
                        field: "action", 
                        key: "g", 
                        title: "Action",
                        width: 50,
                        align: "center",
                        renderBodyCell: ({ row, column, rowIndex }, h) => {
                            return (
                                <span>
                                    <div class="d-flex">
                                        <el-button type="primary" icon="fas fa-edit"  on-click={() =>this.handleEdit(row)} size='mini' class="p-1"></el-button>
                                        <el-button type="danger" icon="el-icon-delete" on-click={() =>this.handleDelete(row)} size='mini' class="p-1"></el-button>
                                    </div>
                                </span>
                            );
                        },
                    },
                ],

            }
        },

        created() {
            this.loadData();
        },


        methods: {

            openModal(ref) {
                this.$refs[ref].open();
            },

            closeModal(ref) {
                this.$refs['modal1'].close();
            },
            
            handleRefresh(){
                this.loading = true;
                this.loadData();
            },

            handleAddComplaint(){
                this.type= 1;
                this.form = {};
                this.title = this.titleAdd;
                this.openModal('modal1');
            },

            async loadData()
            {
                NProgress.start();

                try {

                    const response = await axios.get('v1/xperf/crm/get/complaints');
                    this.all_data = response.data;
                    this.tableData = this.all_data.slice(0);
                    this.loading = false;
                } 
                catch (error) {
                    this.loading = false;
                    this.$notify({
                        type: 'error',
                        title: "Echec de recuperation"
                    });
                }

                NProgress.done();
            },

            handleEdit(param){
                this.type= 2;
                this.form = param;
                this.is_close = this.form.is_close;
                console.log(this.form);
                this.title = this.titleEdit;
                this.openModal('modal1');

            },

            handleDelete(param){
                this.$swal({
                    title: this.$t('global_delete_title'),
                    text:  this.$t('global_alert_continue'),
                    type: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d13737',
                    confirmButtonText:  this.$t('yess'),
                }).then((result) => {
                    if (result.value) {
                        this.delete(param);
                    }
                });
            },

            async delete(user) {

                NProgress.start();
                try {

                    await axios.delete("v1/xperf/crm/delete/complaints/"+user.id);
                    this.loadData();
                    this.$swal(
                        ""+this.$t('global_alert_succes_title')+"",
                        ""+this.$t('global_alert_delelte_succes')+"",
                        'success'
                    );

                } 
                catch (error) {

                    this.loading = false;
                    this.$swal(
                        ""+this.$t('global_alert_echec_title')+"",
                        ""+this.$t('global_alert_echec_text')+"",
                        'error'
                    );
                }

                NProgress.done();
            },

            filterData(){

                let row = [];
                this.all_data.forEach(element => {

                    if (element.state == this.curent_state) {
                        row.push(element);
                    }
                });
                this.tableData = row.slice(0);
            },

        }
    }
</script>
<style>
    .br-0 {
        border-radius: 0px !important;
    }
</style>