<template>
    <div class="m-3">
        <v-row>
            <v-col cols="12" md="4">
                <el-select v-model="value" filterable remote  reserve-keyword  
                    :placeholder="$t('pages.crm.complaints.modal.user')" :remote-method="searchUser" :loading="loading" style="width:100%; border-radius:0px" class="mt-3">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </v-col>
            <v-col cols="12" md="4" >
                <el-date-picker v-model="form.open_date" type="date" :placeholder="$t('pages.crm.complaints.modal.open_date')" style="width:80%; border-radius:0px" class="mt-3"></el-date-picker>
            </v-col>
            <v-col cols="12" md="4" >
                <v-text-field class="purple-input" v-model="form.title" :label="$t('pages.crm.complaints.modal.title')" />
            </v-col>
            <v-col cols="12" md="4" >
                <v-text-field class="purple-input" v-model="form.phone_1" :label="$t('pages.crm.complaints.modal.phone1')" />
            </v-col>
            <v-col cols="12" md="4" >
                <v-text-field class="purple-input" v-model="form.phone_2" :label="$t('pages.crm.complaints.modal.phone2')" />
            </v-col>
            <v-col cols="12" md="4" >
                <el-select v-model="form.state" clearable :placeholder="$t('pages.crm.complaints.modal.state')" style="width:80%; border-radius:0px" class="mt-3">
                    <el-option v-for="item in options_state" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </v-col>
            <v-col cols="12" md="12">
                <vue-editor id="editor" v-model="form.description" >
                </vue-editor>
            </v-col>
            <v-col cols="12" md="2" v-if="type == 2">
                <el-checkbox v-model="form.is_close" class="mt-3">{{$t('pages.crm.complaints.modal.is_close')}}</el-checkbox>
            </v-col>
            <v-col cols="12" md="4" v-if="type == 2">
                <el-date-picker v-model="form.close_date" type="date" :placeholder="$t('pages.crm.complaints.modal.close_date')" style="width:80%; border-radius:0px" class="mt-3"></el-date-picker>
            </v-col>
        </v-row>
        <v-card-actions>
                <v-spacer />
                <v-row>
                    <v-col cols="12" class="text-right" >
                        <el-button type="warning" @click="closeModal()" class="br-0">Annuler</el-button>
                        <el-button type="primary" @click="handleSave()" class="br-0">Enregistrer</el-button>
                    </v-col>
                </v-row>
            </v-card-actions>
    </div>
</template>
<script>

    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';
    import axios from 'axios';
    import moment from 'moment';

    // Import vue editor
    import { VueEditor } from "vue2-editor";


    export default {

        props: ['loadData','closeModal', 'type'],

        components: {
            VueEditor
        },

        data(){
            return {
                form: {
                    open_date: moment().toDate(),
                },
                form_empty: '',
                value: [],
                options: [],
                loading: false,
                curent_user: {},
                options_state: [{
                        value: '0',
                        label: this.$t('pages.crm.complaints.modal.state_label.open')
                    }, {
                        value: '1',
                        label: this.$t('pages.crm.complaints.modal.state_label.progress')
                    }, {
                        value: '2',
                        label: this.$t('pages.crm.complaints.modal.state_label.finish')
                    }
                ],
            }
        },

        created() {
            
        },

        methods: {
            async searchUser(query){
                this.loading = true;
                this.options = [];

                if(query !== '') {

                    NProgress.start();
                    try {
                        let data = { name : query};
                        const response = await axios.post('v1/xperf/crm/get/customers_search', data);
                        let res = response.data;
                        res.forEach(element => {
                            let row = {
                                label: element.name,
                                phone_1: element.phone,
                                phone_2: element.phone_p,
                                email: element.email,
                                value: element.id
                            };

                            this.options.push(row);
                        });

                        console.log(this.options);
                        this.loading = false;
                        NProgress.done();
                    } 
                    catch (error) {

                        this.$notify({
                            type: 'error',
                            title: "Echec de recherche"
                        });
                    }
                } else {
                    this.options = [];
                }
            },

            async handleSave(){

                NProgress.start();

                this.form.user_id = this.form.value;
                this.form.open_date = moment(new Date(this.form.open_date)).startOf('day').format('YYYY-MM-DD');
                this.form.close_date = moment(new Date(this.form.close_date)).startOf('day').format('YYYY-MM-DD');

                console.log(this.form);

                // try {

                    await axios.post("v1/xperf/crm/store/complaints", this.form);
                    
                    this.value = [];
                    this.loadData();
                    this.closeModal();
                    this.form = this.form_empty;

                    this.$swal(
                        ""+this.$t('global_alert_succes_title')+"",
                        ""+this.$t('global_alert_succes_text')+"",
                        'success'
                    );

                // } 
                // catch (error) {

                //     this.loading = false;
                //     this.$swal(
                //         ""+this.$t('global_alert_echec_title')+"",
                //         ""+this.$t('global_alert_echec_text')+"",
                //         'error'
                //     );
                // }
                
                NProgress.done();
            }
        },

        watch: {
            value: function () {

                this.options.forEach(element => {
                    if (element.value == this.value) {
                        this.form = element;
                        this.form.open_date= moment().toDate();
                    }
                });

            }
        },
        
    }
</script>
<style lang="scss" scoped>
    .br-0{
        border-radius: 0px;
    }
</style>